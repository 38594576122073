<template>
  <div class="hello">
    <div class="row col-12">
      <div class="col-6">
        <div class="col-12">
          <div class="col-6">
            <label>{{this.sum}}</label>
            <label for="staticEmail2" class="visually-hidden">Email</label>
            <input v-model="user" type="text" class="form-control" id="staticEmail2" value="0098">
          </div>
          <div class="col-6">
            <label for="inputPassword2" class="visually-hidden">Password</label>
            <input v-model="password" class="form-control" id="inputPassword2" placeholder="Password">
          </div>
          <div class="col-auto">
            <button  type="submit" class="btn btn-primary mb-3" @click="getToken()">ورود</button>
          </div>

          <div>
            <button type="submit" class="btn btn-primary mb-3" @click="start()">شروع ثبت معامله</button>
          </div>
          <div>
            <button type="submit" class="btn btn-primary mb-3" @click="openOrder()">لغو تمام معاملات باز</button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="col-12">
          <div class="col-6">
            <div class="row col-12">
              <div class="col-6">
                <select id="pairs" name="pairs" @click="change()" v-model="pair" class="form-select" aria-label="Default select example">
                  <option v-for="(pair,index) in pairs" v-bind:key="index" :value="pair.id">{{ pair.persianTitle }}
                  </option>
                </select>
              </div>
              <div class="col-6">انتخاب بازار</div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <select v-model="position" class="form-select" aria-label="Default select example">
                  <option value="Buy">خرید</option>
                  <option value="Sell">فروش</option>
                </select>
              </div>
              <div class="col-6">طرف معامله</div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <input v-model="minPrice" class="form-control">
              </div>
              <div class="col-6">بهترین قیمت خرید</div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <input v-model="maxPrice" class="form-control">
              </div>
              <div class="col-6">بهترین قیمت فروش</div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <input v-model="minVolume" class="form-control">
              </div>
              <div class="col-6">حداقل میزان سفارش</div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <input v-model="maxVolume" class="form-control">
              </div>
              <div class="col-6">حداکثر میزان سفارش</div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <input v-model="countOrder" class="form-control">
              </div>
              <div class="col-6">تعداد سفارش</div>
            </div>
            <div class="row col-12">
              <div class="col-6">
                <input v-model="Percentage" class="form-control">
              </div>
              <div class="col-6"> درصد</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "@/axios";
import axios from "axios";
// import moment from 'jalali-moment';
// import convertDate from "@/convertDate";

export default {
  name: 'Robot',
  props: {
    msg: String
  },
  data() {
    return {
      pairs: [
        {
          "id": 1,
          "sourceAsset": "Tether",
          "sourceAssetId": 2,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "USDT/TOMAN",
          "sym": 'usdt',
          "persianTitle": "تتر/تومان",
          "englishTitle": "Tether/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/825.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/USDTsvg"
        },
        {
          "id": 2,
          "sourceAsset": "Ethereum",
          "sourceAssetId": 3,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "ETH/USDT",
          "persianTitle": "اتریوم/تتر",
          "englishTitle": "Ethereum/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1027.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/ETHsvg"
        },
        {
          "id": 3,
          "sourceAsset": "Ethereum",
          "sourceAssetId": 3,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "ETH/TOMAN",
          "persianTitle": "اتریوم/تومان",
          "englishTitle": "Ethereum/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1027.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/ETHsvg"
        },
        {
          "id": 4,
          "sourceAsset": "BinanceCoin",
          "sourceAssetId": 4,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "BNB/USDT",
          "persianTitle": "بایننس کوین/تتر",
          "englishTitle": "BinanceCoin/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1839.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/BNBsvg"
        },
        {
          "id": 5,
          "sourceAsset": "BinanceCoin",
          "sourceAssetId": 4,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "BNB/TOMAN",
          "persianTitle": "بایننس کوین/تومان",
          "englishTitle": "BinanceCoin/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1839.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/BNBsvg"
        },
        {
          "id": 6,
          "sourceAsset": "XRP",
          "sourceAssetId": 5,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "XRP/USDT",
          "persianTitle": "ریپل/تتر",
          "englishTitle": "XRP/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/52.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/XRPsvg"
        },
        {
          "id": 7,
          "sourceAsset": "XRP",
          "sourceAssetId": 5,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "XRP/TOMAN",
          "persianTitle": "ریپل/تومان",
          "englishTitle": "XRP/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/52.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/XRPsvg"
        },
        {
          "id": 8,
          "sourceAsset": "Tron",
          "sourceAssetId": 6,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "TRX/USDT",
          "persianTitle": "ترون/تتر",
          "englishTitle": "Tron/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1958.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/TRXsvg"
        },
        {
          "id": 9,
          "sourceAsset": "Tron",
          "sourceAssetId": 6,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "TRX/TOMAN",
          "persianTitle": "ترون/تومان",
          "englishTitle": "Tron/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1958.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/TRXsvg"
        },
        {
          "id": 10,
          "sourceAsset": "Stellar",
          "sourceAssetId": 7,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "XLM/USDT",
          "persianTitle": "استلار/تتر",
          "englishTitle": "Stellar/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/6636.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/XLMsvg"
        },
        {
          "id": 11,
          "sourceAsset": "Stellar",
          "sourceAssetId": 7,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "XLM/TOMAN",
          "persianTitle": "استلار/تومان",
          "englishTitle": "Stellar/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/6636.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/XLMsvg"
        },
        {
          "id": 12,
          "sourceAsset": "BitCoin",
          "sourceAssetId": 8,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "BTC/USDT",
          "persianTitle": "بیت کوین/تتر",
          "englishTitle": "BitCoin/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/BTCsvg"
        },
        {
          "id": 13,
          "sourceAsset": "BitCoin",
          "sourceAssetId": 8,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "BTC/TOMAN",
          "persianTitle": "بیت کوین/تومان",
          "englishTitle": "BitCoin/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/BTCsvg"
        },
        {
          "id": 14,
          "sourceAsset": "LiteCoin",
          "sourceAssetId": 9,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "LTC/USDT",
          "persianTitle": "لایت کوین/تتر",
          "englishTitle": "LiteCoin/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/LTCsvg"
        },
        {
          "id": 15,
          "sourceAsset": "LiteCoin",
          "sourceAssetId": 9,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "LTC/TOMAN",
          "persianTitle": "لایت کوین/تومان",
          "englishTitle": "LiteCoin/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/LTCsvg"
        },
        {
          "id": 16,
          "sourceAsset": "Cardano",
          "sourceAssetId": 10,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "ADA/USDT",
          "persianTitle": "کاردانو/تتر",
          "englishTitle": "Cardano/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/ADAsvg"
        },
        {
          "id": 17,
          "sourceAsset": "Cardano",
          "sourceAssetId": 10,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "ADA/TOMAN",
          "persianTitle": "کاردانو/تومان",
          "englishTitle": "Cardano/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/ADAsvg"
        },
        {
          "id": 18,
          "sourceAsset": "DOGECOIN",
          "sourceAssetId": 11,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "DOGE/USDT",
          "persianTitle": "دوج کوین/تتر",
          "englishTitle": "DOGECOIN/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/DOGEsvg"
        },
        {
          "id": 19,
          "sourceAsset": "DOGECOIN",
          "sourceAssetId": 11,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "DOGE/TOMAN",
          "persianTitle": "دوج کوین/تومان",
          "englishTitle": "DOGECOIN/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/DOGEsvg"
        },
        {
          "id": 20,
          "sourceAsset": "BITCOIN CASH",
          "sourceAssetId": 12,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "BCH/USDT",
          "persianTitle": "بیت کوین کش/تتر",
          "englishTitle": "BITCOIN CASH/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/BCHsvg"
        },
        {
          "id": 21,
          "sourceAsset": "BITCOIN CASH",
          "sourceAssetId": 12,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "BCH/TOMAN",
          "persianTitle": "بیت کوین کش/تومان",
          "englishTitle": "BITCOIN CASH/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/BCHsvg"
        },
        {
          "id": 22,
          "sourceAsset": "BINANCE USD",
          "sourceAssetId": 13,
          "destinationAsset": "Tether",
          "destinationAssetId": 2,
          "symbol": "BUSD/USDT",
          "persianTitle": "دلار بایننس/تتر",
          "englishTitle": "BINANCE USD/Tether",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/BUSDsvg"
        },
        {
          "id": 23,
          "sourceAsset": "BINANCE USD",
          "sourceAssetId": 13,
          "destinationAsset": "TOMAN",
          "destinationAssetId": 1,
          "symbol": "BUSD/TOMAN",
          "persianTitle": "دلار بایننس/تومان",
          "englishTitle": "BINANCE USD/TOMAN",
          "tradeAllowed": true,
          "isFavorite": false,
          "sourceAssetUrlGraphData": "https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/1.svg",
          "sourceAssetImageAddress": "https://enduserapi.satrex.ir/SatrexStorage/AssetImage/BUSDsvg"
        }
      ],
      user: '00989152028188',
      password: '123456@Abcdefg',
      now : Date.now() ,
      pric: 0,
      pair: 0,
      position: '',
      minPrice: '',
      maxPrice: '',
      minVolume: '',
      maxVolume: '',
      countOrder: 1,
      token: '',
      interval: '',
      cont: 0,
      Percentage: 0,
      orders : [],
      btc: '',
      eth: '',
      usdt: '',
      ada: '',
      bch: '',
      ltc: '',
      bnb: '',
      xlm: '',
      xrp: '',
      trx: '',
      doge: '',
      btcUsdt: '',
      ethUsdt: '',
      usdtUsdt: '',
      adaUsdt: '',
      bchUsdt: '',
      ltcUsdt: '',
      bnbUsdt: '',
      xlmUsdt: '',
      xrpUsdt: '',
      trxUsdt: '',
      dogeUsdt: '',
      sum : 0,
      timer : 0,
      timerReset : 0,
      timerStop : 0,
      orderInterval : 0,
    }
  },
  methods: {
    getToken() {
      axiosApi().post('/api/v1/Token/Get', {
        usernameOrEmail: this.user,
        password: this.password
      }).then(({data}) => {
        this.token = data.data.jwtToken.tokenValue
        alert('شما با موفقیت لاکین شدی')
      }).catch(() => {
        console.log('مشخصات وارد شده اشتباه است')
      })
    },
    start() {
      this.cont = 0
      this.interval = setInterval(
          this.setOrder, 1000)
    },
    setOrder() {
      if ( this.pair === 22 | this.pair === 24)
      {
        console.log('22 24')
        return 'hi';
      }
      this.cont += 1
      if (this.cont > this.countOrder) {
        clearInterval(this.interval);
      } else {
        let price = this.pricePosition();
        if (price > 1)
          price = price.toFixed(0)
        else
          price = price.toFixed(8)


        console.log(price)
        axios({
          method: 'post',
          url: 'https://enduserapi.satrex.ir/api/v1/Order/SetLimitOrder',
          headers: {
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'Accept-Language': 'fa-IR',
            'Authorization': 'bearer ' + this.token,
          },
          data: {
            orderType: this.position,
            pairId: this.pair,
            volume: this.getVolume(),
            price: this.getPrice()
          }
        }).then((data) => {
          console.log(data.data)
          // this.getToken()
        }).catch()
        {
          // this.getToken()
        }
      }
    },
    pricePosition() {
      // if (this.pair === 1 | this.pair === 5 | this.pair === 7 | this.pair === 9 | this.pair === 11 | this.pair === 13 | this.pair === 15 |
      //     this.pair === 19 |this.pair === 21 |this.pair === 23)
      // {
      //   this.maxPrice  = this.maxPrice / 10
      //   this.minPrice = this.minPrice / 10
      // }
      if (this.position === 'Sell') {
        let percentage = this.maxPrice / 100
        return (this.maxPrice / 100) * 100 - percentage * this.Percentage
        // return (this.maxPrice / 100) * 100 - percentage * this.Percentage
      } else {
        let percentage = this.minPrice / 100
        return (this.minPrice / 100) * 100 + percentage * this.Percentage
      }
    },
    getPrice() {
      let price = this.price()
      if (price < this.minPrice || price > this.maxPrice) {
        this.getPrice()
      } else {
        return price
      }
    },
    price() {
      let min = Math.ceil(this.minPrice);
      let max = Math.floor(this.maxPrice);
      let result =  Math.floor(Math.random() * (max - min) + min);
      if(result < 1 )
        result = this.minPrice
      return result ;
    },
    cancel(id,position,createdAtUtc)
    {
      // if (createdAtUtc < this.now)
      //     ''
        axios({
        method: 'post',
        url: 'https://enduserapi.satrex.ir/api/v1/Order/CancelLimitOrder',
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Accept-Language': 'fa-IR',
          'Authorization': 'bearer ' + this.token,
        },
        data: {
          id : id,
          orderType: position,
          createdAtUtc : createdAtUtc
        }
      })
    },
    openOrder()
    {
      axios({
        method: 'post',
        url: 'https://enduserapi.satrex.ir/api/v1/Order/Get',
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Accept-Language': 'fa-IR',
          'Authorization': 'bearer ' + this.token,
        },
        data: {
          OrderStatus : 'Open',
          pageSize: 500,
          // toDateUtc : convertDate()
        }
      }).then((data)=>{
        this.orders = data.data.data.orders
        this.orders.forEach(i => {
          setTimeout(() => {}, 500);
          this.cancel(i.id,i.orderType,i.createdAtUtc)
        })
      })
    }
    ,
    getVolume() {
      let volume = this.volume()
      // if (volume < this.minVolume || volume > this.maxVolume) {
      //    this.getVolume()
      // } else {
      //   if (volume % 1 === 0)
      //   {
      //     console.log(volume)
      //     return volume
      //   }
      //   else
      //   {
      //     return (volume).toFixed(8)
      //   }
      if (volume > 0)
      {
        console.log(volume)
        console.log(typeof volume)
        console.log( '> 0')
        console.log(this.pair)
        // volume = volume.toFixed(8)
        if (this.pair === 1 )
          volume = volume.toFixed(0)
        else
          volume = volume.toFixed(8)
      }
      else
      {
        console.log(volume)
        console.log(typeof volume)
        console.log( ' 0 <')
        volume = (volume).slice(0, 9);
      }
      return volume
      // }
    },
    volume() {
      // let min = Math.ceil(this.maxVolume);
      // let max = Math.floor(max);
      // return Math.floor(Math.random() * (max - min) + min);
      let result = 0
      let min = this.minVolume;
      let max = this.maxVolume;
      result = ((Math.random() * max) + min);
      return result;
    },
    nobitex() {
      axios.get('https://api.nobitex.ir/market/stats?srcCurrency=btc,eth,usdt,ada,bch,ltc,bnb,xlm,xrp,trx,doge&dstCurrency=rls,usdt')
          .then((data) => {
            this.btc = data.data.stats['btc-rls']
            this.eth = data.data.stats['eth-rls']
            this.usdt = data.data.stats['usdt-rls']
            this.ada = data.data.stats['ada-rls']
            this.bch = data.data.stats['bch-rls']
            this.ltc = data.data.stats['ltc-rls']
            this.bnb = data.data.stats['bnb-rls']
            this.xlm = data.data.stats['xlm-rls']
            this.xrp = data.data.stats['xrp-rls']
            this.trx = data.data.stats['trx-rls']
            this.doge = data.data.stats['doge-rls']

            this.btcUsdt = data.data.stats['btc-usdt']
            this.ethUsdt = data.data.stats['eth-usdt']
            this.adaUsdt = data.data.stats['ada-usdt']
            this.bchUsdt = data.data.stats['bch-usdt']
            this.ltcUsdt = data.data.stats['ltc-usdt']
            this.bnbUsdt = data.data.stats['bnb-usdt']
            this.xlmUsdt = data.data.stats['xlm-usdt']
            this.xrpUsdt = data.data.stats['xrp-usdt']
            this.trxUsdt = data.data.stats['trx-usdt']
            this.dogeUsdt = data.data.stats['doge-usdt']
          })
    },
    change() {
      switch (this.pair) {
        case 1:
          // usdt rial
          this.minPrice = this.usdt.bestBuy / 10
          this.maxPrice = this.usdt.bestSell  / 10
          this.minVolume = 5
          this.maxVolume = 10
          break
        case 2:
          this.minPrice = this.ethUsdt.bestBuy
          this.maxPrice = this.ethUsdt.bestSell
          this.minVolume = 0.001
          this.maxVolume = 0.003
          break
        case 3:
          this.minPrice = this.eth.bestBuy  / 10
          this.maxPrice = this.eth.bestSell  / 10
          this.minVolume =  0.001
          this.maxVolume =  0.003
          break
        case 4:
          this.minPrice = this.bnbUsdt.bestBuy
          this.maxPrice = this.bnbUsdt.bestSell
          this.minVolume =  0.00809143
          this.maxVolume =  0.01213715
          break
        case 5:
          this.minPrice = this.bnb.bestBuy  / 10
          this.maxPrice = this.bnb.bestSell  / 10
          this.minVolume =  0.00809143
          this.maxVolume =  0.01213715
          break
        case 6:
          this.minPrice = this.xrpUsdt.bestBuy
          this.maxPrice = this.xrpUsdt.bestSell
          this.minVolume = 3
          this.maxVolume = 10
          break
        case 7:
          this.minPrice = this.xrp.bestBuy  / 10
          this.maxPrice = this.xrp.bestSell  / 10
          this.minVolume = 3
          this.maxVolume = 10
          break
        case 8:
          this.minPrice = this.trxUsdt.bestBuy
          this.maxPrice = this.trxUsdt.bestSell
          this.minVolume = 30
          this.maxVolume = 60
          break
        case 9:
          this.minPrice = this.trx.bestBuy  / 10
          this.maxPrice = this.trx.bestSell  / 10
          this.minVolume = 30
          this.maxVolume = 60
          break
        case 10:
          this.minPrice = this.xlmUsdt.bestBuy
          this.maxPrice = this.xlmUsdt.bestSell
          this.minVolume = 20
          this.maxVolume = 40
          break
        case 11:
          this.minPrice = this.xlm.bestBuy  / 10
          this.maxPrice = this.xlm.bestSell  / 10
          this.minVolume = 20
          this.maxVolume = 40
          break
        case 12:
          this.minPrice = this.btcUsdt.bestBuy
          this.maxPrice = this.btcUsdt.bestSell
          this.minVolume = 0.0003
          this.maxVolume = 0.0005
          break
        case 13:
          this.minPrice = this.btc.bestBuy  / 10
          this.maxPrice = this.btc.bestSell / 10
          this.minVolume = 0.0004
          this.maxVolume = 0.0005
          break
        case 14:
          this.minPrice = this.ltcUsdt.bestBuy
          this.maxPrice = this.ltcUsdt.bestSell
          this.minVolume = 0.02051282
          this.maxVolume = 0.03008113
          break
        case 15:
          this.minPrice = this.ltc.bestBuy / 10
          this.maxPrice = this.ltc.bestSell / 10
          this.minVolume = 0.02051282
          this.maxVolume = 0.03008113
          break
        case 16:
          this.minPrice = this.adaUsdt.bestBuy
          this.maxPrice = this.adaUsdt.bestSell
          this.minVolume = 4
          this.maxVolume = 8
          break
        case 17:
          this.minPrice = this.ada.bestBuy / 10
          this.maxPrice = this.ada.bestSell / 10
          this.minVolume = 4
          this.maxVolume = 8
          break
        case 18:
          this.minPrice = this.dogeUsdt.bestBuy
          this.maxPrice = this.dogeUsdt.bestSell
          this.minVolume = 20
          this.maxVolume = 30
          break
        case 19:
          this.minPrice = this.doge.bestBuy / 10
          this.maxPrice = this.doge.bestSell / 10
          this.minVolume = 10
          this.maxVolume = 20
          break
        case 20:
          this.minPrice = this.bchUsdt.bestBuy
          this.maxPrice = this.bchUsdt.bestSell
          this.minVolume = 0.00723789
          this.maxVolume = 0.00947577
          break
        case 21:
          this.minPrice = this.bch.bestBuy / 10
          this.maxPrice = this.bch.bestSell / 10
          this.minVolume = 0.00423789
          this.maxVolume = 0.00847577
          break
        case 22:
          this.minPrice = ''
          this.maxPrice = ''
          this.minVolume = ''
          this.maxVolume = ''
          break
        case 23:
          this.minPrice = this.usdt.bestBuy / 10
          this.maxPrice = this.usdt.bestSell  / 10
          this.minVolume = 5
          this.maxVolume = 10
          break


      }
      // console.log(this.pair)
    },
    percentage(price) {
      return price
    },
    startTimer() {

      setInterval(() => {
            this.sum += 1
        if (this.sum == 65)
        {
          this.pageReloud()
        }
          }
          , 1000)
    },
    pageReloud() {
      location.reload()
    },
    startOrdering()
    {
      this.startTimer()
      this.timerStop = setInterval(()=>{
        this.pric = this.pricePosition().toFixed(8)
        this.pair += 1
        this.cont = 0
        this.change()
        this.position = 'Sell'
        this.setOrder()
        this.cont = 0
        this.position = 'Buy'
        this.setOrder()
        if (this.pair > 23 )
        {
          clearInterval(this.timerStop)
        }
      },2000)
      this.change()



    }
  },
  mounted() {
    // this.getToken()
    this.nobitex()
    // this.startOrdering()
  }
}
</script>
<style scoped>
</style>
